<template>
<div>
	<!-- 修改密码 -->
	<el-dialog title="修改手机号" :visible.sync="pwdVisible" :close-on-click-modal="false" width="570px" >
		<div id="password">
		    <div class="password">
		      <div class="steps">
		        <div class="list" :class="{'active':number>0}">
		          <div class="number">1</div>
		          <span>验证身份</span>
		        </div>
		        <span></span>
		        <div class="list" :class="{'active':number>1}">
		          <div class="number">2</div>
		          <span>绑定手机</span>
		        </div>
		        <span></span>
		        <div class="list" :class="{'active':number>2}">
		          <div class="number">3</div>
		          <span>绑定成功</span>
		        </div>
		      </div>
		      <div class="identity" v-if="number == 1">
		        <div class="way">
		          <label class="way-label">验证方式</label>
		          <span @click="wayActiveBtn(1)">
		            <i class="icon" :class="{'active':wayActive == 1}"><b></b></i>手机号验证
		          </span>
		          <span @click="wayActiveBtn(2)">
		            <i class="icon" :class="{'active':wayActive == 2}"><b></b></i>邮箱验证
		          </span>
		        </div>
		        <div class="phone" v-if="wayActive == 1" :key="1">
							<el-form label-position="left" label-width="80px" size="small"
								ref="validForm" :model="fromData" :rules="validRules">
								<el-form-item label="手机号码" prop="mobile">
									<el-input  v-model="userInfo.mobile" placeholder="未添加手机号码,请添加" :disabled="true" ></el-input>
								</el-form-item>
								<el-form-item >
									<huakuai :mobile="userInfo.mobile" empty-str="请先绑定手机号码" width="430px"  @isOk="isOk" ref="huakuai" />
								</el-form-item>
								<el-form-item label="验证码"  prop="valid_code">
									<div class="code-box">
										<el-input placeholder="请输入验证码" v-model="fromData.valid_code"></el-input>
										<div class="code-msg-box">
											<el-button
											  @click="getGenerate"
											  :disabled="codeDisabled"
											  style="width: 100%"
											  type="danger"
											  plain
											  >{{ showWorld }}</el-button>
										</div>
									</div>
								</el-form-item>
							</el-form>
		        </div>
		        <div class="emil" v-if="wayActive == 2" :key="2">
							<el-form label-position="left" label-width="80px" size="small"
								ref="validForm" :model="fromData" :rules="validRules">
								<el-form-item label="邮箱"  prop="email">
									<el-input v-model="userInfo.email" :disabled="true"  width="270px" placeholder="请输入邮箱"></el-input>
								</el-form-item>
								<el-form-item label="验证码"  prop="valid_code">
									<div class="code-box">
										<el-input placeholder="请输入验证码" v-model="fromData.valid_code"></el-input>
										<div class="code-msg-box">
											<el-button
											  @click="emailValidBtn"
											  style="width: 100%"
											  type="danger"
											  plain >{{ showWorld }}</el-button >
										</div>
									</div>
								</el-form-item>
							</el-form>
		        </div>
		      </div>
					<!-- 第二步设置密码 -->
		      <div class="password user-mobile-box" v-if="number == 2">
						<el-form label-position="left" label-width="80px" ref="pwdForm" size="small" :model="fromData" :rules="pwdRules">
							<el-form-item label="新手机号" prop="new_mobile">
								<el-input v-model="fromData.new_mobile"  @blur="pwdBlur"  width="270px" placeholder="请输入新的手机号"></el-input>
							</el-form-item>
							<el-form-item label="" >
								<huakuai :mobile="fromData.new_mobile" width="430px"  @isOk="isOkMobile" ref="phoneHuakuai" />
							</el-form-item>
							<el-form-item label="验证码"  prop="new_code">
								<div class="code-box">
									<el-input placeholder="请输入验证码" v-model="fromData.new_code"></el-input>
									<div class="code-msg-box">
										<el-button
										  @click="mobileValidBtn"
										  style="width: 100%"
										  type="danger"
											:disabled="mobileDisabld"
										  plain >{{ emailValid.showWorld }}</el-button >
									</div>
								</div>
							</el-form-item>
						</el-form>
		      </div>
					<!-- 第三步改密成功 -->
		      <div class="successful" v-if="number == 3">
		        <p>
		          <img src="@/assets/img/account/confirm.png" />
		          <span>新手机绑定成功，<span style="color:#F72626">{{down}}</span>后关闭弹窗！</span>
		        </p>
		      </div>
		    </div>
		</div>
		<div slot="footer">
			<div class="dialog-footer" v-if="number==1">
				<el-button size="small" class="btn" @click="pwdVisible = false">取消</el-button>
				<el-button size="small" class="btn" type="danger"  @click="stepFirstBtn">下一步</el-button>
			</div>
			<div class="dialog-footer" v-if="number==2">
				<el-button size="small" class="btn" type="danger" plain @click="number=1">上一步</el-button>
				<el-button size="small" class="btn" :disabled="submitDisabed" type="danger" @click="submitPwdBtn">提交</el-button>
			</div>
			<div class="dialog-footer" v-if="number==3">
				<el-button size="small" class="btn"  @click="pwdVisible = false">返回</el-button>
			</div>
		</div>
	</el-dialog>

</div>
</template>

<script>
	import huakuai from "@/views/login/loginHome/huakuai.vue";
	import { validatePhoneTwo, validatePassword,validateEMail } from "@/utils/validate.js";
	export default {
		components:{
			huakuai,
		},
		data(){
			let validRules = {
				email:[{
					validator:(rule,val,call)=>{
						if(this.wayActive == 2){
							if(!this.userInfo.email){
								call(new Error("请先绑定邮箱"))
							}else{
								call()
							}
						}else{
							call();
						}
					}
				}],
				mobile:[{
					validator:(rule,val,call)=>{
						if(this.wayActive == 1){
							if(!this.userInfo.mobile){
								call(new Error("请先绑定手机号"))
							}else{
								call()
							}
						}else{
							call();
						}
					}
				}],
				valid_code:[{trigger:'blur',validator:(rule,val,call)=>{
						if(!this.fromData.valid_code){
							call(new Error("请输入验证码"))
						}else{
							call();
						}
				}}]
			}
			let pwdRules = {
				new_mobile:[{trigger:'blur',validator:validatePhoneTwo}],
				new_pwd_confirm:[{trigger:'blur',validator:(rules,val,call)=>{
					if(!val){
						call(new Error("确认密码不能为空"))
					}else if(this.fromData.new_pwd != val){
						call(new Error("两次密码不相同，请重新输入"))
					}else{
						call();
					}
				}}],
			}
			return {
				pwdVisible:false,
				callFn:null,
				validRules,
				pwdRules,
				number: 1,        //当前步数
				userInfo:{
					mobile:'',
					email:"",
				},
				disable:true,     //是否禁用下一步按钮
				codeDisabled:true, //验证码提交
				emailDisabled:false, 
				mobileDisabld:true, //滑块验证
				submitDisabed:true, //修改提交
				down:10,
				wayActive:1,      //验证方式
				saveData:{},
				saveMobile:{},
				fromData:{
				  valid_type:'mobile',
				  valid_code:'',
					new_mobile:'',
					new_code:'',
				},
				
				showWorld:"点击获取验证码",
				timers:null,
				n:90,
				emailTimers:null,
				emailValid:{
					showWorld:"点击获取验证码",
					n:90,
				}
			}
		},
		created(){
			
		},
		mounted(){
			
		},
		methods: {
			open(data,call){
				this.userInfo.mobile = data.user_mobile,
				this.userInfo.email = data.user_email,
				this.pwdVisible = true;
				this.callFn = call;
			},
			countDown(){
				if(!this.pwdVisible){return false;}
				setTimeout(()=>{
					if(this.down<1){
						this.pwdVisible = false;
					}else{
						this.down = this.down - 1;
						this.countDown();
					}
				},1000)
			},
			getUserInfo(){
				this.$axios.get("/base/user/login_info").then((res) => {
				  if (res.code == 200) {
						let data = res.data;
						this.userInfo.mobile = data.user_mobile;
						this.userInfo.email = data.user_email;
				  }
				},(err) => {
				  this.$refs.huakuai.resets();
				});
			},
			getGenerate(){
				//获取验证码
				let val = this.saveData;
				if(!val.data1){
					return console.log("图片验证失败")
				}
				let parma = {
				  valid_type:'mobile',
				  first: {
				    x: val.data1.x,
				    y: val.data1.y,
				    time: val.data1.time,
				  },
				  second: {
				    x: val.data2.x,
				    y: val.data2.y,
				    time: val.data2.time,
				  },
				};
				// console.log('params',parma);
				this.$axios.postJSON("/base/user/mobile/send_code", parma).then((res) => {
				  if (res.code == 200) {
				    this.$message.success("获取成功");
				    const that = this;
				    this.timers = setInterval(() => {
				      that.n--;
				      if (that.n < 1) {
				        clearInterval(that.timers);
				        that.$refs.huakuai.resets();
				        that.showWorld = "点击获取验证码";
				        that.n = 90;
				      } else {
				        that.showWorld = that.n + "秒后再次获取";
				        that.disable = true;
				      }
				    }, 1000);
				  }
				},(err) => {
				  this.$refs.huakuai.resets();
				});
			},
			mobileValidBtn(){ //第二步新手机号验证
				if(this.mobileDisabld){
					return this.$message.warning("请进行滑块验证")
				}
				let val = this.saveMobile;
				if(!val.data1){
					return console.log("图片验证失败")
				}
				let params = {
				  new_mobile:Number(this.fromData.new_mobile),
				  first: {
				    x: val.data1.x,
				    y: val.data1.y,
				    time: val.data1.time,
				  },
				  second: {
				    x: val.data2.x,
				    y: val.data2.y,
				    time: val.data2.time,
				  },
				};
				
				this.$axios.postJSON("/base/user/mobile/send_mobile_code",params).then((res) => {
				  if (res.code == 200) {
						this.$message.success("获取成功");
						const that = this;
						this.emailTimers = setInterval(() => {
						  that.emailValid.n--;
						  if (that.emailValid.n < 1) {
						    clearInterval(that.emailTimers);
						    that.emailValid.showWorld = "点击获取验证码";
						    that.emailValid.n = 90;
						  } else {
						    that.emailValid.showWorld = that.emailValid.n + "秒后再次获取";
						    that.emailDisabled = true;
						  }
						}, 1000);
				  }
				},(err) => {
				  this.$refs.phoneHuakuai.resets();
				});
			},
			isOk(val){
				if (val.isOk) {
				  this.codeDisabled = false;
				  this.saveData = val;
				}
			},
			isOkMobile(val){
				if (val.isOk) {
				  this.mobileDisabld = false;
				  this.saveMobile = val;
				}
			},
			pwdBlur(){
				this.mobileDisabld = this.submitDisabed = !this.$utils.regPhone(this.fromData.new_mobile);
			},
			stepFirstBtn(){
				this.$refs.validForm.validate((valid)=>{
					if(valid){
						this.number = 2;
					}
				})
			},
			wayActiveBtn(num){
				this.wayActive = num;
				this.fromData.valid_type = num==1?'mobile':'email';
			},
			submitPwdBtn(){
				this.$refs.pwdForm.validate((valid)=>{
					if(valid){
						// return console.log(this.fromData);
						this.fromData.new_mobile = Number(this.fromData.new_mobile);
						this.$axios.postJSON("/base/user/mobild",this.fromData).then(res=>{
							this.number = 3;
							this.down = 5;
							this.callFn && this.callFn({mobile:this.fromData.new_mobile})
							this.countDown();
						}).catch(err=>{
							// console.log(err);
							// err.msg && this.$message.error(err.msg);
						})
					}
				})
			},
			loginBtn(){
				
			},
			emailValidBtn(){ //第一步手机验证
				let that = this;
				if(!this.userInfo.email){
					return this.$message.error("请先绑定邮箱")
				}
				this.$axios.postJSON("/base/user/mobile/send_code",{valid_type:'email'}).then(res=>{
					this.$message.success("验证码发送成功");
					console.log('email succ',res);
					this.timers = setInterval(() => {
					  that.n--;
					  if (that.n < 1) {
					    clearInterval(that.timers);
					    that.$refs.huakuai.resets();
					    that.showWorld = "点击获取验证码";
					    that.n = 90;
					  } else {
					    that.showWorld = that.n + "秒后再次获取";
					    that.disable = true;
					  }
					}, 1000);
				}).catch(err=>{
					console.log(err);
					err.msg && this.$message.error(err.msg);
				})
			},
			
			
			
		},
	}
</script>

<style scoped lang="scss">
	.dialog-footer{
		text-align: center;
		.btn{
			width:80px;
		}
	}
//修改密码
	#password{
		.user-mobile-box{
			padding-left: 5px;
			padding-right:15px;
		}
		.password{
	    >.steps{
	      display: flex;
	      align-items: center;
	      justify-content: space-between;
				width:450px;
				margin: 0 auto 30px;
	      >.list {
	        display: flex;
	        align-items: center;
	        color: rgba(204, 204, 204, 100);
	        font-size: 18px;
	        letter-spacing: 1px;
					
	        >.number {
	          width: 20px;
	          height: 20px;
	          border-radius: 50%;
	          line-height: 20px;
	          text-align: center;
	          border: 1px solid rgba(204, 204, 204, 100);
	          margin-right: 3px;
	          font-size: 14px;
	        }
	      }
	
	      >.active {
	        color: rgba(67, 135, 248, 100);
	
	        >.number {
	          border-color: rgba(67, 135, 248, 100);
	        }
	      }
	
	      >span {
	        width: 60px;
	        height: 1px;
	        background-color: rgba(204, 204, 204, 100);
	      }
	    }
			.huakuai-valid{
				margin-top: 15px;
			}
			
			.code-box{
				display: flex;
				.code-msg-box{
					width:150px;
					margin-left:20px;
				}
			}
			
			
	    // 验证身份
	    >.identity{
				padding:0 10px;
	      >.way{
	        display: flex;
	        align-items: center;
	        height: 32px;
	        margin-bottom: 20px;
	        .way-label{
	          width: 80px;
	          box-sizing: border-box;
	        }
	        >span{
	          margin-right: 40px;
	          display: flex;
	          align-items: center;
	          cursor: pointer;
	          >i{
	            width: 16px;
	            height: 16px;
	            border-radius: 50%;
	            display: flex;
	            align-items: center;
	            justify-content: center;
	            border: 1px solid rgba(220, 224, 231, 100);
	            margin-right: 5px;
	            >b{
	              width: 8px;
	              height: 8px;
	              border-radius: 50%;
	              background-color: #F74848;
	              display: none;
	            }
	          }
	          >.active{
	            border-color: #F74848;
	            >b{
	              display: block;
	            }
	          }
	        }
	      }
	    }
	    // 改密成功
	    >.successful{
	      display: flex;
	      flex-direction: column;
	      align-items: center;
	      justify-content: center;
	      margin-top: 70px;
	      >p{
	        color: #333333;
	        font-size: 18px;
	        display: flex;
	        align-items: center;
	        height: 30px;
	        >img{
	          width: 32px;
	          height: 32px;
	          margin-right: 5px;
	        }
	      }
	      >span{
	        line-height: 20px;
	        margin-top: 10px;
	      }
	    }
	  }
	}
	
</style>
