<template>
  <div>
    <div class="framework   binding">
      <div class="header-title">第三方绑定</div>
      <div class="content">
        <div class="list">
          <div class="name">
            <img src="@/assets/img/account/binding.png" class="icon" />
            <span>手机绑定</span>
            <img src="@/assets/img/account/help.png" class="help" />
          </div>
          <div class="state">{{ userInfo.mobile || "未绑定" }}</div>
          <div class="btn" @click="editMobileBtn">修改手机</div>
        </div>
        <div class="list">
          <div class="name">
            <img :src="
                userInfo.email
                  ? require('@/assets/img/account/binding.png')
                  : require('@/assets/img/account/unbounded.png')
              " class="icon" />
            <span>邮箱绑定</span>
            <img src="@/assets/img/account/help.png" class="help" />
          </div>
          <div class="state">{{ userInfo.email || "未绑定" }}</div>
          <div class="btn" @click="emailChange">
            {{ userInfo.email ? "修改" : "绑定" }}邮箱
          </div>
        </div>
        <div class="list">
          <div class="name">
            <img
              :src="
                userInfo.wx_bind.id
                  ? require('@/assets/img/account/binding.png')
                  : require('@/assets/img/account/unbounded.png')
              "
              class="icon"
            />
            <span>微信绑定</span>
            <img src="@/assets/img/account/help.png" class="help" />
          </div>
          <div class="state">
            {{ userInfo.wx_bind.id ? "已绑定" : "未绑定微信账号" }}
          </div>
          <div class="btn" @click="bindWX">
            {{ userInfo.wx_bind.id ? "解除绑定" : "绑定微信" }}
          </div>
        </div>
      </div>
    </div>

    <!-- 绑定邮箱 -->
    <editEmail ref="editEmail" />

    <!-- 修改手机 -->
    <editMobile ref="editMobile" />
  </div>
</template>

<script>
import editMobile from "./editMobile";
import editEmail from "./editEmail";
export default {
  components: {
    editMobile,
    editEmail,
  },
  data() {
    return {
      userInfo: {
        mobile: "",
        email: "",
        wx_bind: {},
      },
    };
  },
  created() {
    this.getUserInfo();
  },
  mounted() {
    let script = document.createElement("script");
    script.src =
      "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
    let login = document.getElementById("account");
    login.appendChild(script);
  },
  methods: {
    editMobileBtn() {
      console.log("userInfo", this.userInfo);
      let params = {
        user_mobile: this.userInfo.mobile,
        user_email: this.userInfo.email,
      };
      this.$refs.editMobile.open(params, (data) => {
        if (data.mobile) {
          let val = data.mobile,
            len = val.length,
            start = val.substr(0, 3),
            end = val.substr(len - 4, len);
          let mobile_str = start + "****" + end;
          this.userInfo.mobile = mobile_str;
          this.$store.commit("updateUserInfo", { mobile: mobile_str });
        }
      });
    },
    emailChange() {
      this.emailVisible = true;
      let params = {
        mobile: this.userInfo.mobile,
        email: this.userInfo.email,
      };
      this.$refs.editEmail.open(params, (data) => {
        this.userInfo.email = data.email;
      });
    },
    bindWX() {
      if (this.userInfo.wx_bind.id) {
        this.$axios.postJSON("/base/user/unbind/weixin").then(() => {
          this.userInfo.wx_bind = {};
          this.$message.success("解绑成功");
        });
      } else {
        this.$router.push({
          path: "/loginWx",
          query:{
            bind:1,
          }
        });
      }
    },
    getUserInfo() {
      this.$axios.get("/base/user/login_info").then(
        (res) => {
          if (res.code == 200) {
            let data = res.data;
            this.userInfo.mobile = data.user_mobile;
            this.userInfo.email = data.user_email;
            this.userInfo.wx_bind = data.wx_bind || {};
          }
        },
        (err) => {}
      );
    },
  },
};
</script>

<style scoped lang="scss">
.framework {
  width: 593px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .header-title {
    color: rgba(51, 51, 51, 1);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
.binding {
  > .content {
    > .list {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 20px;
      > .name {
        color: #333;
        display: flex;
        align-items: center;
        margin-right: 50px;
        > .icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        > span {
          width: 60px;
        }
        > .help {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
      > .state {
        color: #999;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > .btn {
        color: #4387f8;
        cursor: pointer;
        margin-left: 10px;
        width: 60px;
      }
    }
  }
}
.dialog-footer {
  text-align: center;
  .btn {
    width: 80px;
  }
}
</style>
