<template>
  <div>
    <el-dialog title="注销用户" :visible.sync="visible" :close-on-click-modal="false" width="570px">
      <div id="logoff">
        <div class="logoff">
          <div class="steps">
            <div class="list" :class="{ active: number > 0 }">
              <div class="number">1</div>
              <span>验证身份</span>
            </div>
            <span></span>
            <div class="list" :class="{ active: number > 1 }">
              <div class="number">2</div>
              <span>注销成功</span>
            </div>
          </div>
          <div class="identity" v-if="number == 1">
            <div class="way">
              <label class="way-label">验证方式</label>
              <span>
                <i class="icon active"><b></b></i>
                手机号验证
              </span>
            </div>
            <div class="phone" :key="1">
              <el-form
                label-position="left"
                label-width="80px"
                size="small"
                ref="validForm"
                :model="fromData"
                :rules="logoffRules"
              >
                <el-form-item label="手机号码" prop="mobile">
                  <el-input
                    v-model="userInfo.mobile"
                    placeholder="未添加手机号码,请添加"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <huakuai
                    :mobile="userInfo.mobile"
                    empty-str="请先绑定手机号码"
                    width="430px"
                    @isOk="isOk"
                    ref="huakuai"
                  />
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                  <div class="code-box">
                    <el-input placeholder="请输入验证码" v-model="fromData.code"></el-input>
                    <div class="code-msg-box">
                      <el-button
                        @click="getGenerate"
                        :disabled="codeDisabled"
                        style="width: 100%"
                        type="danger"
                        plain
                      >
                        {{ showWorld }}
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 第二步改密成功 -->
          <div class="successful" v-if="number == 2">
            <p>
              <img src="@/assets/img/account/confirm.png" />
              <span>
                注销成功，
                <span style="color:#f74848">{{ numThree }}</span>
                秒后回到平台首页
              </span>
            </p>
            <span>将全网注销退出，请重新注册</span>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="dialog-footer" v-if="number == 1">
          <el-button size="small" class="btn" @click="visible = false">取消</el-button>
          <el-button size="small" class="btn" type="danger" @click="stepFirstBtn">下一步</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import huakuai from "@/views/login/loginHome/huakuai.vue";
export default {
  name: "",
  components: {
    huakuai,
  },
  props: {},
  created() {},
  mounted() {},
  data() {
    let logoffRules = {
      code: [
        {
          trigger: "blur",
          validator: (rule, val, call) => {
            if (!this.fromData.code) {
              call(new Error("请输入验证码"));
            } else {
              call();
            }
          },
        },
      ],
    };
    return {
      visible: false,
      number: 1, //当前步数
      logoffRules,
      showWorld: "点击获取验证码",
      fromData: {
        code: "",
      },
      codeDisabled: true, //验证码提交
      saveData: {},
      userInfo: {
        mobile: "",
      },
      timersThree: null,
      numThree: 3,
      timers: null,
      n: 90,
    };
  },
  watch: {},
  computed: {},
  methods: {
    open() {
      this.reSetData();
      this.getUserInfo();
      this.visible = true;
    },
    reSetData() {
      this.number = 1;
      this.fromData.code = "";
      this.codeDisabled = true;
      this.saveData = {};
      this.numThree = 3;
      this.n = 90;
      this.timersThree = null;
      this.timers = null;
    },
    isOk(val) {
      console.log(val);
      if (val.isOk) {
        this.codeDisabled = false;
        this.saveData = val;
      }
    },
    getUserInfo() {
      this.$axios.get("/base/user/login_info").then(
        res => {
          if (res.code == 200) {
            let data = res.data;
            this.userInfo.mobile = data.user_mobile;
          } else {
            this.$refs.huakuai.resets();
            this.reSetData();
          }
        },
        err => {
          this.$refs.huakuai.resets();
          this.reSetData();
        }
      );
    },
    getGenerate() {
      //获取验证码
      let val = this.saveData;
      if (!val.data1) {
        return console.log("图片验证失败");
      }
      let parma = {
        first: {
          x: val.data1.x,
          y: val.data1.y,
          time: val.data1.time,
        },
        second: {
          x: val.data2.x,
          y: val.data2.y,
          time: val.data2.time,
        },
      };
      // console.log('params',parma);
      this.$axios.postJSON("/base/user/logout/send_code", parma).then(
        res => {
          if (res.code == 200) {
            this.$message.success("获取成功");
            const that = this;
            this.timers = setInterval(() => {
              that.n--;
              if (that.n < 1) {
                clearInterval(that.timers);
                that.$refs.huakuai.resets();
                that.showWorld = "点击获取验证码";
                that.n = 90;
              } else {
                that.showWorld = that.n + "秒后再次获取";
                that.codeDisabled = true;
              }
            }, 1000);
          } else {
            this.$refs.huakuai.resets();
            this.reSetData();
          }
        },
        err => {
          this.$refs.huakuai.resets();
          this.reSetData();
        }
      );
    },
    stepFirstBtn() {
      this.$refs.validForm.validate(valid => {
        if (valid) {
          let parma = {
            code: this.fromData.code,
          };
          // console.log('params',parma);
          this.$axios.postJSON("/base/user/logout", parma).then(
            res => {
              if (res.code == 200) {
                this.$message.success(res.msg);
                this.number = 2;
                const that = this;
                this.timersThree = setInterval(() => {
                  that.numThree--;
                  if (that.numThree < 1) {
                    clearInterval(that.timersThree);

                    setTimeout(() => {
                    if (process.env.NODE_ENV === "production") {
                      sessionStorage.removeItem("AccessToken");
                    } else {
                      localStorage.removeItem("AccessToken");
                    }
                    this.$router.push("/login");
                    }, 200);
                  }
                }, 1000);
              } else {
                this.reSetData();
                this.visible = false;
              }
            },
            err => {
              this.$refs.huakuai.resets();
              this.reSetData();
            }
          );
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#logoff {
  .logoff {
    > .steps {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 450px;
      margin: 0 auto 30px;
      > .list {
        display: flex;
        align-items: center;
        color: rgba(204, 204, 204, 100);
        font-size: 18px;
        letter-spacing: 1px;

        > .number {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          line-height: 20px;
          text-align: center;
          border: 1px solid rgba(204, 204, 204, 100);
          margin-right: 3px;
          font-size: 14px;
        }
      }

      > .active {
        color: rgba(67, 135, 248, 100);

        > .number {
          border-color: rgba(67, 135, 248, 100);
        }
      }

      > span {
        width: 220px;
        height: 1px;
        background-color: rgba(204, 204, 204, 100);
      }
    }
    .huakuai-valid {
      margin-top: 15px;
    }

    .code-box {
      display: flex;
      .code-msg-box {
        width: 150px;
        margin-left: 20px;
      }
    }

    // 验证身份
    > .identity {
      padding: 0 10px;
      > .way {
        display: flex;
        align-items: center;
        height: 32px;
        margin-bottom: 20px;
        .way-label {
          width: 80px;
          box-sizing: border-box;
        }
        > span {
          margin-right: 40px;
          display: flex;
          align-items: center;
          cursor: pointer;
          > i {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(220, 224, 231, 100);
            margin-right: 5px;
            > b {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #f74848;
              display: none;
            }
          }
          > .active {
            border-color: #f74848;
            > b {
              display: block;
            }
          }
        }
      }
    }
    // 改密成功
    > .successful {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 70px;
      > p {
        color: #333333;
        font-size: 18px;
        display: flex;
        align-items: center;
        height: 30px;
        > img {
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }
      }
      > span {
        line-height: 20px;
        margin-top: 10px;
      }
    }
  }
}
.dialog-footer {
  text-align: center;
  .btn {
    width: 80px;
  }
}
</style>
