<template>
  <!-- 账号管理页面 -->
  <div id="account">
    <!-- <information></information> -->
    <!-- <userInfo></userInfo> -->
    <div class="body">
      <div class="left">
        <!-- 登录安全 -->
        <loginSafe></loginSafe>
        <!-- 收货地址 -->
        <devAddress></devAddress>
      </div>
      <div class="right">
        <!-- 第三方绑定 -->
        <socialBind></socialBind>

        <!-- 登录记录 -->
        <loginLog></loginLog>
      </div>
    </div>
  </div>
</template>

<script>
import userInfo from "@/views/mySelf/components/userInfo.vue";
import loginSafe from "@/views/mySelf/account/components/loginSafe.vue";
import socialBind from "@/views/mySelf/account/components/socialBind.vue";
import devAddress from "@/views/mySelf/account/components/devAddress.vue";
import loginLog from "@/views/mySelf/account/components/loginLog.vue";
export default {
  components: {
    userInfo,
    loginSafe,
    socialBind,
    devAddress,
    loginLog,
  },
  data() {
    return {
      placeValue: true,
      pageValue: true,
      address: [{ addressDefault: true }, { addressDefault: false }],
    };
  },
  created() {
    this.$store.commit("changeChoseType", 3);
  },
  methods: {
    setAddressDefault(index) {
      //设置默认收获地址
      this.address[index].addressDefault = !this.address[index].addressDefault;
      this.address.map((item, e) => {
        if (this.address[index].addressDefault && index != e) {
          this.address[e].addressDefault = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#account {
  > .body {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}
</style>
