<template>
<div>
	<!-- 登录记录 -->
	<div class="framework login-record">
	  <div class="header-title">登录记录<span @click="seeLogsBtn">查看全部>></span></div>
	  <p>为您保留最近50次的登录记录</p>
	  <div class="content">
	    <div class="line"></div>
	    <div class="list" v-for="(item,index) in list" :key="index">
	      <div class="icon qq" v-if="item.login_method==1">
	        <img src="@/assets/img/account/qq.png" />
	      </div>
				<div class="icon weixin"  v-if="item.login_method==2">
				  <img src="@/assets/img/account/weixin.png" />
				</div>
				<div class="icon other"  v-if="item.login_method==3">
				  <img src="@/assets/img/account/other.png" />
				</div>
				<div class="icon qq"  v-if="item.login_method==4">
				  <img src="@/assets/img/account/computer.png" />
				</div>
	      <div class="text">
	        <span>于<i>{{item.login_equipment}}</i>登录</span>
	        <span><img src="@/assets/img/account/time.png" />{{item.login_date}}</span>
	      </div>
	    </div>
	  </div>
	</div>
	<el-dialog :visible.sync="visible" title="登录记录" class="dialog-header-line">
		<el-table :data="tableData">
			<el-table-column label="序号" type="index" width="60" align="center">
			</el-table-column>
			<el-table-column label="登录设备" prop="login_equipment" width="120">
			</el-table-column>
			<el-table-column label="登录时间" prop="login_date">
			</el-table-column>
			<el-table-column label="登录IP" prop="login_ip">
			</el-table-column>
		</el-table>
		<div class="page-box">
			<el-pagination
			  background
				:page-size="10"
				@current-change="pageChange"
			  layout="total,prev, pager, next"
			  :total="pages.total">
			</el-pagination>
		</div>

	</el-dialog>
</div>
</template>

<script>
	export default {
		data(){
			return {
				list:[],
				visible:false,
				tableData:[],
				dataAll:[],
				pages:{
					total:0,
					page_size:10,
					page_num:1,
				},
			}
		},
		created(){
			this.getData();
		},
		mounted(){
			
		},
		methods: {
			getData(){
				this.$axios.get('/base/user/login/logs').then(res=>{
					if(res.data){
						res.data.map(it=>{
							let date = it.login_date;
							it.login_date = it.login_date.slice(0,date.length-3);
						});
						this.dataAll = this.$utils.cloneDeep(res.data);
						this.pages.total = this.dataAll.length;
						if(res.data.length>5){
							res.data.length = 5;
						}
						this.list = res.data;
					}
				})
			},
			seeLogsBtn(){
				this.visible = true;
				this.pageChange(1)
			},
			pageChange(data){
				this.pages.page_num = data;
				let {page_size,page_num, total} = this.pages
				let start = page_size * (page_num-1), count = page_num * page_size,
					end = total>count?count:total,arr = [];
				for(let i = start;i < end;i++ ){
					arr.push(this.dataAll[i])
				}
				this.tableData = arr;
			},
		},
	}
</script>

<style scoped lang="scss">
	.framework{
	  width: 593px;
	  background-color: #fff;
	  border-radius: 8px;
	  padding: 20px;
	  box-sizing: border-box;
	  margin-bottom: 15px;
	  .header-title{
	    color: rgba(51, 51, 51, 100);
	    font-size: 16px;
	    font-weight: bold;
	    margin-bottom: 20px;
	    position: relative;
	    >span{
	      color: rgba(247, 72, 72, 100);
	      font-size: 12px;
	      position: absolute;
	      right: 0;
	      top: 2px;
	      cursor: pointer;
	    }
	  }
	}
	.login-record{
	  >p{
	    color: #999;
	    font-size: 12px;
	    margin-bottom: 20px;
	  }
	  >.content{
	    padding: 0 20px;
	    font-size: 12px;
	    position: relative;
	    >.line{
	      position: absolute;
	      width: 2px;
	      height: 250px;
	      background-color: rgba(242, 242, 242, 100);
	      left: 35px;
	      top: 15px;
	      z-index: 0;
	    }
	    >.list{
	      display: flex;
	      align-items: center;
	      margin-bottom: 25px;
	      >.icon{
	        width: 30px;
	        height: 30px;
	        border-radius: 50%;
	        display: flex;
	        justify-content: center;
	        align-items: center;
	        margin-right: 20px;
	        position: relative;
	        z-index: 1;
	        >img{
	          width: 16px;
	          height: 16px;
	        }
	      }
	      >.qq{
	        background-color: #8EB7FB;
	      }
	      >.weixin{
	        background-color: #AFDD9A;
	      }
	      >.other{
	        background-color: #FBD68E;
	      }
	      >.text{
	        flex: 1;
	        overflow: hidden;
	        height: 38px;
	        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.05);
	        border-radius: 3px;
	        color: #666;
	        display: flex;
	        align-items: center;
	        justify-content: space-between;
	        padding: 0 10px;
	        box-sizing: border-box;
	        >span{
	          line-height: 20px;
	          display: flex;
	          align-items: center;
	          >i{
	            color: #333;
	            font-weight: bold;
	            margin: 0 3px;
							font-style:normal;
	          }
	          >img{
	            width: 16px;
	            height: 16px;
	            margin-right: 5px;
	          }
	        }
	      }
	    }
	  }
	}
	.page-box{
		margin-top: 20px;
		text-align: right;
	}
</style>
