<template>
  <div class="alls">
    <div class="tongyong">
      <span class="showKuang"
        >{{ address.province.label || "省" }}
        <i
          @click="delets(1)"
          v-if="address.province.label"
          class="el-icon-circle-close closeAll"
        ></i>
      </span>
      <el-popover
        transition="none"
        placement="bottom"
        width="540"
        trigger="click"
      >
        <div class="titles">
          <span
            @click="choseProvince(item)"
            :class="[
              'spn',
              address.province.value == item.value ? 'isChose' : '',
            ]"
            v-for="item in province"
            :key="item.value"
            :title="item.label"
          >
            {{ item.label }}
          </span>
        </div>
        <i slot="reference" class="el-icon-caret-bottom"></i>
      </el-popover>
    </div>
    <div class="tongyong">
      <span class="showKuang"
        >{{ address.city.label || "市" }}
        <i
          @click="delets(2)"
          v-if="address.city.label"
          class="el-icon-circle-close closeAll"
        ></i>
      </span>
      <el-popover
        transition="none"
        placement="bottom"
        width="540"
        trigger="click"
      >
        <div class="titles">
          <span
            @click="choseCity(item)"
            :class="['spn', address.city.value == item.value ? 'isChose' : '']"
            v-for="item in city"
            :key="item.value"
            :title="item.label"
          >
            {{ item.label }}
          </span>
        </div>
        <i slot="reference" class="el-icon-caret-bottom"></i>
      </el-popover>
    </div>
    <div class="tongyong">
      <span class="showKuang"
        >{{ address.area.label || "区" }}
        <i
          @click="delets(3)"
          v-if="address.area.label"
          class="el-icon-circle-close closeAll"
        ></i>
      </span>
      <el-popover
        transition="none"
        placement="bottom"
        width="540"
        trigger="click"
      >
        <div class="titles">
          <span
            @click="choseArea(item)"
            :class="['spn', address.area.value == item.value ? 'isChose' : '']"
            v-for="item in area"
            :key="item.value"
            :title="item.label"
          >
            {{ item.label }}
          </span>
        </div>
        <i slot="reference" class="el-icon-caret-bottom"></i>
      </el-popover>
    </div>
  </div>
</template>

<script>
import allAddress from "@/utils/districts.js";
export default {
  props: {
    getAddress: {
      // 收到的参数，做回显 code
      type: Object,
      default: () => {
        return {
          province: "",
          city: "",
          area: "",
        };
      },
    },
    getAddressName: {
      // 收到的参数，做回显 name
      type: Object,
      default: () => {
        return {
          province: "",
          city: "",
          area: "",
        };
      },
    },
    adress: {
      type: Boolean,
    },
  },
  data() {
    return {
      address: {
        province: {
          value: "",
          label: "",
        },
        city: {
          value: "",
          label: "",
        },
        area: {
          value: "",
          label: "",
        },
      },
      province: [],
      city: [],
      area: [],
    };
  },
  created() {
    let array = [];
    for (let keys in allAddress[100000]) {
      array.push({
        value: keys,
        label: allAddress[100000][keys],
      });
    }
    this.province = array;

    if (this.getAddress.province) {
      let msg = this.province.find((res) => {
        return res.value == this.getAddress.province;
      });
      this.address.province = msg;
    }

    if (this.getAddress.city && this.getAddress.province) {
      this.getCity();
      let msg = this.city.find((res) => {
        return res.value == this.getAddress.city;
      });
      if (msg) {
        this.address.city = msg;
      }
    }

    if (
      this.getAddress.area &&
      this.getAddress.city &&
      this.getAddress.province
    ) {
      this.getArea();
      let msg = this.area.find((res) => {
        return res.value == this.getAddress.area;
      });
      if (msg) {
        this.address.area = msg;
      }
    }

    if (this.getAddressName.province) {
      let msg = this.province.find((res) => {
        return res.label == this.getAddressName.province;
      });
      this.address.province = JSON.parse(JSON.stringify(msg));
    }

    if (this.getAddressName.city && this.getAddressName.province) {
      this.getCity();
      let msg = this.city.find((res) => {
        return res.label == this.getAddressName.city;
      });
      if (msg) {
        this.address.city = JSON.parse(JSON.stringify(msg));
      }
    }

    if (
      this.getAddressName.area &&
      this.getAddressName.city &&
      this.getAddressName.province
    ) {
      this.getArea();
      let msg = this.area.find((res) => {
        return res.label == this.getAddressName.area;
      });
      if (msg) {
        this.address.area = JSON.parse(JSON.stringify(msg));
      }
    }
  },
  methods: {
    delets(val) {
      if (val == 1) {
        this.address.province.value = 0;
        this.address.province.label = "";
				this.address.city.value = 0;
				this.address.city.label = "";
				this.address.area.value = 0;
				this.address.area.label = "";
      } else if (val == 2) {
        this.address.city.value = 0;
        this.address.city.label = "";
				this.address.area.value = 0;
				this.address.area.label = "";
      } else if (val == 3) {
        this.address.area.value = 0;
        this.address.area.label = "";
      }
    },
    remove() {
      this.address.province = {};
      this.address.city = {};
      this.address.area = {};
    },

    resets() {
      this.address = {
        province: {
          value: "",
          label: "",
        },
        city: {
          value: "",
          label: "",
        },
        area: {
          value: "",
          label: "",
        },
      };
    },
    choseProvince(val) {
      this.address.province = {
				...val,
			};
      this.address.city = {
        value: "",
        label: "",
      };
      this.address.area = {
        value: "",
        label: "",
      };
      this.getCity();
    },
    choseCity(val) {
      this.address.city = {
				...val,
			};
      this.address.area = {
        value: "",
        label: "",
      };
      this.getArea();
    },
    choseArea(val) {
      this.address.area = {
				...val,
			};
      console.log("this.address==>", this.address);
      this.$emit("address", this.address);
    },

    getCity() {
      let array = [];
      for (let keys in allAddress[this.address.province.value]) {
        array.push({
          value: keys,
          label: allAddress[this.address.province.value][keys],
        });
      }
      this.city = array;
    },
    getArea() {
      let array = [];
      for (let keys in allAddress[this.address.city.value]) {
        array.push({
          value: keys,
          label: allAddress[this.address.city.value][keys],
        });
      }
      this.area = array;
    },
  },
};
</script>

<style lang="scss" scoped>
.alls {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
}
.tongyong {
  width: 32%;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  & > span:nth-child(1) {
    flex: 1;
    color: #606266;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  i {
    width: 14px;
    color: #999999;
    line-height: 32px;
    cursor: pointer;
  }
}
.titles {
  padding: 10px;
}
.spn {
  display: inline-block;
  width: 130px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    color: #0088fe;
  }
}
.isChose {
  color: #0088fe;
}
.showKuang {
  position: relative;
}
.closeAll {
  position: absolute;
  right: 2px;
}
</style>