<template>
  <div>
    <div class="framework address">
      <div class="header-title">收货地址</div>
      <div class="content">
        <div class="list" v-for="(item, index) in address" :key="item.id">
          <div class="top">
            <span>{{ item.contacts_name }}</span>
            <i>{{ item.contacts_mobile }}</i>
            <label v-if="item.is_default">默认</label>
          </div>
          <div class="text">
            <p>{{ item.address }}</p>
            <img src="@/assets/img/account/editor.png" @click="editItemBtn(item)" class="cursor" />
          </div>
          <div class="bottom">
            <div class="default" @click="setDefaultBtn(item)">
              <img v-if="item.is_default" src="@/assets/img/account/default.png" />
              <i v-else class="icon-radio"></i>
              <span>设为默认</span>
            </div>
            <span @click="deltItem(item)">删除</span>
          </div>
        </div>

        <div class="btn" @click="addItemBtn">
          <img src="@/assets/img/account/add.png" />
          添加收货地址
        </div>
      </div>
    </div>
    <!-- 添加修改地址 -->
    <el-dialog
      :visible.sync="visible"
      :title="isAdd ? '新增收货地址' : '修改收货地址'"
      width="650px"
    >
      <div class="form-box">
        <el-form
          label-position="left"
          label-width="100px"
          size="small"
          :model="form"
          :rules="formRules"
          ref="editForm"
        >
          <el-form-item label="联系人姓名" prop="contacts_name">
            <el-input v-model="form.contacts_name"></el-input>
          </el-form-item>
          <el-form-item>
            <div slot="label">
              <span class="konggefu"></span>
              地图定位
            </div>
            <!-- <span class="cursor" @click="mapOpenBtn"><i class="icon-map el-icon-location"></i></span> -->
            <fw-map
              :change="true"
              :address="form"
              :showInput="false"
              :onlyAddress="false"
              @mapAddress="submitsAddress"
            />
          </el-form-item>
          <el-form-item label="所在地区" prop="area">
            <pickerAddress
              :key="areaKey"
              :getAddress="getAddressCode"
              :getAddressName="getAddressName"
              ref="pickerAddress"
            ></pickerAddress>
          </el-form-item>
          <el-form-item label="街道地址" prop="address">
            <el-input v-model="form.address" maxlength="36"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="contacts_mobile">
            <el-input v-model="form.contacts_mobile"></el-input>
          </el-form-item>
          <el-form-item label="" prop="post_code">
            <div slot="label">
              <span class="konggefu"></span>
              邮编编码
            </div>
            <el-input v-model.number="form.post_code" maxlength="6"></el-input>
          </el-form-item>
          <el-form-item>
            <div slot="label">
              <span class="konggefu"></span>
              是否默认
            </div>
            <el-radio-group v-model="form.is_default">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" class="btn" @click="visible = false">取消</el-button>
        <el-button size="small" class="btn" type="danger" @click="submitFormBtn">提交</el-button>
      </div>
    </el-dialog>
    <!-- <baiduLocation @submitsAddress="submitsAddress" ref="baiduLocation" /> -->
  </div>
</template>

<script>
import pickerAddress from "./pickerAddress.vue";
// import baiduLocation from './mapLocation.vue'
import { validatePhoneTwo, validatePassword, validateEMail } from "@/utils/validate.js";
export default {
  components: {
    pickerAddress,
    // baiduLocation,
  },
  data() {
    let formRules = {
      contacts_name: [
        {
          required: true,
          message: "请输入联系人姓名",
          trigger: "blur",
        },
      ],
      area: [
        {
          required: true,
          message: "请选择所在地区",
          trigger: "blur",
          validator: (rules, val, call) => {
            if (!this.form.area) {
              call(new Error("请选择所在地区"));
            } else {
              call();
            }
          },
        },
      ],
      address: [
        {
          required: true,
          message: "请输入街道地址",
          trigger: "blur",
        },
      ],
      contacts_mobile: [
        {
          required: true,
          trigger: "blur",
          validator: validatePhoneTwo,
        },
      ],
    };
    return {
      address: [],
      visible: false,
      isAdd: true,
      formRules,
      areaKey: +new Date(),
      getAddressCode: {},
      getAddressName: {},
      form: {
        contacts_name: "",
        province: "",
        province_code: "",
        city: "",
        city_code: "",
        area: "",
        area_code: "",
        address: "",
        contacts_mobile: "",
        post_code: "",
        is_default: true,
      },
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    getData() {
      this.$axios.get("/base/user/address").then(res => {
        if (res.code == 200) {
          if (res.data) {
            this.address = res.data;
          }
        }
      });
    },
    deltItem(row) {
      this.$confirm("是否删除该收货地址？")
        .then(() => {
          this.$axios.deltJSON("/base/user/address", { id: row.id }).then(res => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              this.getData();
            }
          });
        })
        .catch(err => {
          console.log("cancel", err);
        });
    },
    editItemBtn(item) {
      this.visible = true;
      this.getAddressName = {
        province: item.province,
        city: item.city,
        area: item.area,
      };
      this.areaKey = +new Date();
      this.form = this.$utils.cloneDeep(item);
      this.isAdd = false;
    },
    addItemBtn() {
      this.isAdd = true;
      (this.form.contacts_name = ""),
        (this.form.province = ""),
        (this.form.province_code = ""),
        (this.form.city = ""),
        (this.form.city_code = ""),
        (this.form.area = ""),
        (this.form.area_code = ""),
        (this.form.address = ""),
        (this.form.contacts_mobile = ""),
        (this.form.post_code = ""),
        (this.form.is_default = true);
      if (this.form.id) {
        delete this.form.id;
      }
      this.getAddressName = {
        province: "",
        city: "",
        area: "",
      };
      this.areaKey = +new Date();
      this.visible = true;
    },
    mapOpenBtn() {
      let lat = 30.67892489053518,
        lng = 104.03950305108553;
      // this.$refs.baiduLocation.open(lng,lat);
      this.$refs.baiduLocation.open();
    },
    setDefaultBtn(row) {
      this.$axios.putJSON("/base/user/address/set_default", { id: row.id }).then(res => {
        this.getData();
        this.$message.success("设置成功");
      });
    },
    submitsAddress(obj) {
      // console.log('data',val);
      // this.form.address = val.address;
      // this.form.lat =Number(val.lat.toFixed(2));
      // this.form.lng = Number(val.lng.toFixed(2)) ;
      // this.form.province = val.addressComponents.province;
      // this.form.city = val.addressComponents.city;
      // this.form.area = val.addressComponents.district;
      // this.form.post_code = val.post_code||'';
      // this.getAddressName.province = val.addressComponents.province;
      // this.getAddressName.city = val.addressComponents.city;
      // this.getAddressName.area = val.addressComponents.district;
      // this.areaKey = + new Date();
      let val = JSON.parse(obj);
      this.form.address = val.address;
      this.form.lat =Number(val.lat.toFixed(2));
      this.form.lng = Number(val.lng.toFixed(2)) ;
      this.form.province = val.province;
      this.form.city = val.city;
      this.form.area = val.district;
      this.form.post_code = val.post_code||'';
      this.getAddressName.province = val.province;
      this.getAddressName.city = val.city;
      this.getAddressName.area = val.district;
      this.areaKey = + new Date();
    },
    submitFormBtn() {
      let addressd = this.$refs.pickerAddress.address;
      this.form.province = addressd.province.label;
      this.form.province_code = Number(addressd.province.value);
      this.form.city = addressd.city.label;
      this.form.city_code = Number(addressd.city.value);
      this.form.area = addressd.area.label;
      this.form.area_code = Number(addressd.area.value);
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.form.contacts_mobile = Number(this.form.contacts_mobile);
          if (!this.form.post_code) {
            delete this.form.post_code;
          }
          if (this.isAdd) {
            // console.log('form',this.form);
            this.$axios.postJSON("/base/user/address", this.form).then(res => {
              if (res.code == 200) {
                this.$message.success("添加成功");
                this.visible = false;
                this.getData();
              }
            });
          } else {
            this.$axios.putJSON("/base/user/address", this.form).then(res => {
              if (res.code == 200) {
                this.$message.success("修改成功");
                this.visible = false;
                this.getData();
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.framework {
  width: 593px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .header-title {
    color: rgba(51, 51, 51, 1);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
.address {
  > .content {
    color: #666;
    > .list {
      width: 100%;
      padding: 20px 25px;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: rgba(250, 250, 250, 100);
      margin-bottom: 20px;
      > .top {
        margin-bottom: 15px;
        > label {
          text-align: center;
          font-size: 12px;
          border-radius: 3px;
          color: #f74848;
          background-color: #ffcece;
          padding: 2px 5px;
          margin-left: 16px;
        }
        > i {
          color: rgba(153, 153, 153, 100);
          font-size: 12px;
          margin-left: 5px;
          font-style: normal;
        }
      }
      > .text {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: 15px;
        border-bottom: 1px solid #dce0e7;
        > p {
          width: 364px;
          line-height: 20px;
        }
        > img {
          width: 16px;
          cursor: pointer;
        }
      }
      > .bottom {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        margin-top: 10px;
        > .default {
          cursor: pointer;
          display: flex;
          align-items: center;
          .icon-radio {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 1px solid rgba(220, 224, 231, 100);
            margin-right: 5px;
          }
          > img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }
        > span {
          cursor: pointer;
          color: #333;
        }
        > span:hover {
          color: #f74848;
        }
      }
    }
    > .btn {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(250, 250, 250, 100);
      border-radius: 3px;
      color: #f74848;
      cursor: pointer;
      > img {
        width: 20px;
        margin-right: 5px;
      }
    }
  }
}
.form-box {
  padding-left: 20px;
  padding-right: 20px;
}
.icon-map {
  font-size: 20px;
  color: #4387f8;
}
.dialog-footer {
  text-align: center;
  .btn {
    width: 80px;
  }
}
</style>
<style lang="scss">
.konggefu {
  display: inline-block;
  width: 10px;
  height: 14px;
}
</style>
