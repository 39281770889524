<template>
  <div>
    <div class="framework login">
      <div class="title">
        <div class="left">登录安全</div>
        <div class="right" @click="logoffBtn">注销用户</div>
      </div>
      <div class="content">
        <div class="list">
          <span>登录密码</span>
          <label>建议您定期更换密码</label>
          <i class="change-pwd-btn" @click="pwdChangeBtn">修改密码</i>
        </div>
        <div class="list">
          <span>异地登录</span>
          <label>异地登录时需验证身份，防止异地盗号</label>
          <div class="switch">
            <el-switch
              v-model="placeValue"
              active-color="#4387F8"
              inactive-color="#E5E5E5"
            ></el-switch>
          </div>
        </div>
        <div class="list">
          <span>网页登录</span>
          <label>网页登录时需验证身份，防止缓存信息被窃</label>
          <div class="switch">
            <el-switch
              v-model="pageValue"
              active-color="#4387F8"
              inactive-color="#E5E5E5"
            ></el-switch>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改密码 -->
    <el-dialog
      title="设置密码"
      :visible.sync="pwdVisible"
      :close-on-click-modal="false"
      width="570px"
    >
      <div id="password">
        <div class="password">
          <div class="steps">
            <div class="list" :class="{ active: number > 0 }">
              <div class="number">1</div>
              <span>验证身份</span>
            </div>
            <span></span>
            <div class="list" :class="{ active: number > 1 }">
              <div class="number">2</div>
              <span>设置密码</span>
            </div>
            <span></span>
            <div class="list" :class="{ active: number > 2 }">
              <div class="number">3</div>
              <span>改密成功</span>
            </div>
          </div>
          <div class="identity" v-if="number == 1">
            <div class="way">
              <label class="way-label">验证方式</label>
              <span @click="wayActiveBtn(1)">
                <i class="icon" :class="{ active: wayActive == 1 }"><b></b></i>
                手机号验证
              </span>
              <span @click="wayActiveBtn(2)">
                <i class="icon" :class="{ active: wayActive == 2 }"><b></b></i>
                邮箱验证
              </span>
            </div>
            <div class="phone" v-if="wayActive == 1" :key="1">
              <el-form
                label-position="left"
                label-width="80px"
                size="small"
                ref="validForm"
                :model="fromData"
                :rules="validRules"
              >
                <el-form-item label="手机号码" prop="mobile">
                  <el-input
                    v-model="userInfo.mobile"
                    placeholder="未添加手机号码,请添加"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <huakuai
                    :mobile="userInfo.mobile"
                    empty-str="请先绑定手机号码"
                    width="430px"
                    @isOk="isOk"
                    ref="huakuai"
                  />
                </el-form-item>
                <el-form-item label="验证码" prop="valid_code">
                  <div class="code-box">
                    <el-input placeholder="请输入验证码" v-model="fromData.valid_code"></el-input>
                    <div class="code-msg-box">
                      <el-button
                        @click="getGenerate"
                        :disabled="codeDisabled"
                        style="width: 100%"
                        type="danger"
                        plain
                      >
                        {{ showWorld }}
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="emil" v-if="wayActive == 2" :key="2">
              <el-form
                label-position="left"
                label-width="80px"
                size="small"
                ref="validForm"
                :model="fromData"
                :rules="validRules"
              >
                <el-form-item label="邮箱" prop="email">
                  <el-input
                    v-model="userInfo.email"
                    :disabled="true"
                    width="270px"
                    placeholder="请输入邮箱"
                  ></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="valid_code">
                  <div class="code-box">
                    <el-input placeholder="请输入验证码" v-model="fromData.valid_code"></el-input>
                    <div class="code-msg-box">
                      <el-button @click="emailValidBtn" style="width: 100%" type="danger" plain>
                        {{ showWorld }}
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 第二步设置密码 -->
          <div class="password" v-if="number == 2">
            <el-form
              label-position="left"
              label-width="80px"
              ref="pwdForm"
              size="small"
              :model="fromData"
              :rules="pwdRules"
            >
              <el-form-item label="新的密码" prop="new_pwd">
                <el-input
                  v-model="fromData.new_pwd"
                  type="password"
                  @blur="pwdBlur"
                  show-password
                  width="270px"
                  placeholder="请输入新的密码"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="new_pwd_confirm">
                <el-input
                  v-model="fromData.new_pwd_confirm"
                  type="password"
                  show-password
                  width="270px"
                  placeholder="请输入确认密码"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <!-- 第三步改密成功 -->
          <div class="successful" v-if="number == 3">
            <p>
              <img src="@/assets/img/account/confirm.png" />
              <span>修改登录密码成功！</span>
            </p>
            <span>为了您的账号安全，改密成功后，将全网注销退出，请重新登录</span>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="dialog-footer" v-if="number == 1">
          <el-button size="small" class="btn" @click="pwdVisible = false">取消</el-button>
          <el-button size="small" class="btn" type="danger" @click="stepFirstBtn">下一步</el-button>
        </div>
        <div class="dialog-footer" v-if="number == 2">
          <el-button size="small" class="btn" type="danger" plain @click="number = 1">
            上一步
          </el-button>
          <el-button
            size="small"
            class="btn"
            :disabled="submitDisabed"
            type="danger"
            @click="submitPwdBtn"
          >
            提交
          </el-button>
        </div>
        <div class="dialog-footer" v-if="number == 3">
          <el-button
            size="small"
            class="btn"
            :disabled="submitDisabed"
            type="danger"
            @click="loginBtn"
          >
            重新登录
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 注销弹窗 -->
    <logoffBox ref="logoffBox" />
  </div>
</template>

<script>
import huakuai from "@/views/login/loginHome/huakuai.vue";
import logoffBox from "./logoffBox.vue";
import { validatePhoneTwo, validatePassword, validateEMail } from "@/utils/validate.js";
export default {
  components: {
    huakuai,
    logoffBox
  },
  data() {
    let validRules = {
      email: [
        {
          validator: (rule, val, call) => {
            if (this.wayActive == 2) {
              if (!this.userInfo.email) {
                call(new Error("请先绑定邮箱"));
              } else {
                call();
              }
            } else {
              call();
            }
          },
        },
      ],
      mobile: [
        {
          validator: (rule, val, call) => {
            if (this.wayActive == 1) {
              if (!this.userInfo.mobile) {
                call(new Error("请先绑定手机号"));
              } else {
                call();
              }
            } else {
              call();
            }
          },
        },
      ],
      valid_code: [
        {
          trigger: "blur",
          validator: (rule, val, call) => {
            if (!this.fromData.valid_code) {
              call(new Error("请输入验证码"));
            } else {
              call();
            }
          },
        },
      ],
    };
    let pwdRules = {
      new_pwd: [{ trigger: "blur", validator: validatePassword }],
      new_pwd_confirm: [
        {
          trigger: "blur",
          validator: (rules, val, call) => {
            if (!val) {
              call(new Error("确认密码不能为空"));
            } else if (this.fromData.new_pwd != val) {
              call(new Error("两次密码不相同，请重新输入"));
            } else {
              call();
            }
          },
        },
      ],
    };

    return {
      placeValue: true,
      pageValue: true,
      pwdVisible: false,
      validRules,
      pwdRules,
      number: 1, //当前步数
      userInfo: {
        // mobile:'18483663369',
        // email:'zhangtangwen@feiwin.com',
        mobile: "",
        email: "",
      },
      disable: true, //是否禁用下一步按钮
      codeDisabled: true, //验证码提交
      emailDisabled: false,
      submitDisabed: true, //修改提交

      identity: true, //验证身份
      password: false, //设置密码
      successful: false, //改密成功
      wayActive: 1, //验证方式
      saveData: {},
      fromData: {
        valid_type: "mobile",
        valid_code: "",
        new_pwd: "",
        new_pwd_confirm: "",
      },

      showWorld: "点击获取验证码",
      timers: null,
      n: 90,
    };
  },
  created() {
    this.getUserInfo();
  },
  mounted() {},
  methods: {
    pwdChangeBtn() {
      this.pwdVisible = true;
    },
    getUserInfo() {
      this.$axios.get("/base/user/login_info").then(
        res => {
          if (res.code == 200) {
            let data = res.data;
            this.userInfo.mobile = data.user_mobile;
            this.userInfo.email = data.user_email;
          }
        },
        err => {
          this.$refs.huakuai.resets();
        }
      );
    },
    getGenerate() {
      //获取验证码
      let val = this.saveData;
      if (!val.data1) {
        return console.log("图片验证失败");
      }
      let parma = {
        valid_type: "mobile",
        first: {
          x: val.data1.x,
          y: val.data1.y,
          time: val.data1.time,
        },
        second: {
          x: val.data2.x,
          y: val.data2.y,
          time: val.data2.time,
        },
      };
      // console.log('params',parma);
      this.$axios.postJSON("/base/user/pwd/send_code", parma).then(
        res => {
          if (res.code == 200) {
            this.$message.success("获取成功");
            const that = this;
            this.timers = setInterval(() => {
              that.n--;
              if (that.n < 1) {
                clearInterval(that.timers);
                that.$refs.huakuai.resets();
                that.showWorld = "点击获取验证码";
                that.n = 90;
              } else {
                that.showWorld = that.n + "秒后再次获取";
                that.disable = true;
              }
            }, 1000);
          }
        },
        err => {
          this.$refs.huakuai.resets();
        }
      );
    },
    isOk(val) {
      if (val.isOk) {
        this.codeDisabled = false;
        this.saveData = val;
      }
    },
    pwdBlur() {
      this.submitDisabed = !this.fromData.new_pwd;
    },
    stepFirstBtn() {
      this.$refs.validForm.validate(valid => {
        if (valid) {
          this.number = 2;
        }
      });
    },
    wayActiveBtn(num) {
      this.wayActive = num;
      this.fromData.valid_type = num == 1 ? "mobile" : "email";
    },
    submitPwdBtn() {
      this.$refs.pwdForm.validate(valid => {
        if (valid) {
          // console.log(this.fromData);
          this.$axios
            .putJSON("/base/user/pwd", this.fromData)
            .then(res => {
              this.number = 3;
            })
            .catch(err => {
              // console.log(err);
              // err.msg && this.$message.error(err.msg);
            });
        }
      });
    },
    loginBtn() {
      if (process.env.NODE_ENV === "production") {
        sessionStorage.removeItem("AccessToken");
      } else {
        localStorage.removeItem("AccessToken");
      }
      this.$router.push({
        path: "/login",
      });
    },
    emailValidBtn() {
      if (!this.userInfo.email) {
        return this.$message.error("请先绑定邮箱");
      }
      this.$axios
        .postJSON("/base/user/pwd/send_code", { valid_type: "email" })
        .then(res => {
          this.$message.success("验证码发送成功");
          console.log("email succ", res);
          this.timers = setInterval(() => {
            that.n--;
            if (that.n < 1) {
              clearInterval(that.timers);
              that.$refs.huakuai.resets();
              that.showWorld = "点击获取验证码";
              that.n = 90;
            } else {
              that.showWorld = that.n + "秒后再次获取";
              that.disable = true;
            }
          }, 1000);
        })
        .catch(err => {
          console.log(err);
          err.msg && this.$message.error(err.msg);
        });
    },
    logoffBtn() {
      this.$confirm(
        "本操作将彻底删除用户，请确认是否有关联企业。注销后无法通过本手机号或用户名密码登录平台及本用户所在企业。",
        "提示",
        {
          confirmButtonText: "注销",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "centered-confirm",
        }
      )
        .then(() => {
          this.$refs.logoffBox.open()
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
// 公用样式
.framework {
  width: 593px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 15px;
  > .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    > .left {
      color: rgba(51, 51, 51, 100);
      font-size: 16px;
      font-weight: bold;
    }
    > .right {
      color: #4387f8;
      cursor: pointer;
      margin-right: 12px;
    }
  }
}
// 登录安全
.login {
  > .content {
    > .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 15px;
      height: 40px;
      > span {
        color: #666;
        width: 70px;
      }
      > label {
        color: #999;
        font-size: 14px;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > i {
        color: #4387f8;
        margin-left: 10px;
        width: 80px;
        text-align: center;
        cursor: pointer;
        font-style: normal;
      }
      > .switch {
        margin-left: 10px;
        width: 80px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.dialog-footer {
  text-align: center;
  .btn {
    width: 80px;
  }
}
//修改密码
#password {
  .password {
    > .steps {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 450px;
      margin: 0 auto 30px;
      > .list {
        display: flex;
        align-items: center;
        color: rgba(204, 204, 204, 100);
        font-size: 18px;
        letter-spacing: 1px;

        > .number {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          line-height: 20px;
          text-align: center;
          border: 1px solid rgba(204, 204, 204, 100);
          margin-right: 3px;
          font-size: 14px;
        }
      }

      > .active {
        color: rgba(67, 135, 248, 100);

        > .number {
          border-color: rgba(67, 135, 248, 100);
        }
      }

      > span {
        width: 60px;
        height: 1px;
        background-color: rgba(204, 204, 204, 100);
      }
    }
    .huakuai-valid {
      margin-top: 15px;
    }

    .code-box {
      display: flex;
      .code-msg-box {
        width: 150px;
        margin-left: 20px;
      }
    }

    // 验证身份
    > .identity {
      padding: 0 10px;
      > .way {
        display: flex;
        align-items: center;
        height: 32px;
        margin-bottom: 20px;
        .way-label {
          width: 80px;
          box-sizing: border-box;
        }
        > span {
          margin-right: 40px;
          display: flex;
          align-items: center;
          cursor: pointer;
          > i {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(220, 224, 231, 100);
            margin-right: 5px;
            > b {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #f74848;
              display: none;
            }
          }
          > .active {
            border-color: #f74848;
            > b {
              display: block;
            }
          }
        }
      }
    }
    // 改密成功
    > .successful {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 70px;
      > p {
        color: #333333;
        font-size: 18px;
        display: flex;
        align-items: center;
        height: 30px;
        > img {
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }
      }
      > span {
        line-height: 20px;
        margin-top: 10px;
      }
    }
  }
}
</style>
