<template>
  <el-dialog
    :visible.sync="emailVisible"
    title="绑定邮箱"
    class="dialog-header-line"
    width="570px"
    :close-on-click-modal="false"
  >
    <div class="dialog-bind-box">
      <div class="password">
        <div class="steps">
          <div class="list" :class="{ active: number > 0 }">
            <div class="number">1</div>
            <span>验证身份</span>
          </div>
          <span></span>
          <div class="list" :class="{ active: number > 1 }">
            <div class="number">2</div>
            <span>绑定邮箱</span>
          </div>
          <span></span>
          <div class="list" :class="{ active: number > 2 }">
            <div class="number">3</div>
            <span>绑定成功</span>
          </div>
        </div>
        <div class="identity" v-if="number == 1">
          <div class="phone" :key="1">
            <el-form
              label-position="left"
              label-width="80px"
              size="small"
              ref="validForm"
              :model="fromData"
              :rules="validRules"
            >
              <el-form-item label="手机号码" prop="mobile">
                <el-input
                  v-model="userInfo.mobile"
                  placeholder="未添加手机号码,请添加"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <huakuai
                  :mobile="userInfo.mobile"
                  empty-str="请先绑定手机号码"
                  width="430px"
                  @isOk="isOk"
                  ref="huakuai"
                />
              </el-form-item>
              <el-form-item label="验证码" prop="mobile_code">
                <div class="code-box">
                  <el-input
                    placeholder="请输入验证码"
                    v-model="fromData.mobile_code"
                  ></el-input>
                  <div class="code-msg-box">
                    <el-button
                      @click="getGenerate"
                      :disabled="codeDisabled"
                      style="width: 100%"
                      plain
                      >{{ showWorld }}</el-button
                    >
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 第二步设置密码 -->
        <div class="password" v-if="number == 2">
          <el-form
            label-position="left"
            label-width="80px"
            ref="pwdForm"
            size="small"
            :model="fromData"
            :rules="validRules"
          >
            <el-form-item label="邮箱" prop="email">
              <el-input
                v-model="fromData.email"
                @blur="pwdBlur"
                width="270px"
                placeholder="请输入新的邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="email_code">
              <div class="code-box">
                <el-input
                  placeholder="请输入验证码"
                  v-model="fromData.email_code"
                ></el-input>
                <div class="code-msg-box">
                  <el-button
                    @click="emailGenerate"
                    :disabled="emailDisabled"
                    style="width: 100%"
                    plain
                    >{{ emailValid.showWorld }}</el-button
                  >
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <!-- 第三步改密成功 -->
        <div class="successful" v-if="number == 3">
          <p>
            <img src="@/assets/img/account/confirm.png" />
            <span
              >邮箱绑定成功，<span style="color: #f72626">{{ down }}</span
              >秒后自动关闭弹窗！</span
            >
          </p>
        </div>
      </div>
    </div>
    <div slot="footer">
      <div class="dialog-footer" v-if="number == 1">
        <el-button size="small" class="btn" @click="emailVisible = false"
          >取消</el-button
        >
        <el-button size="small" class="btn" type="danger" @click="stepFirstBtn"
          >下一步</el-button
        >
      </div>
      <div class="dialog-footer" v-if="number == 2">
        <el-button
          size="small"
          class="btn"
          type="danger"
          plain
          @click="number = 1"
          >上一步</el-button
        >
        <el-button
          size="small"
          class="btn"
          :disabled="submitDisabed"
          type="danger"
          @click="submitEmailBtn"
          >提交</el-button
        >
      </div>
      <div class="dialog-footer" v-if="number == 3">
        <el-button
          size="small"
          class="btn"
          :disabled="submitDisabed"
          type="danger"
          @click="emailVisible = false"
          >返回</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import huakuai from "@/views/login/loginHome/huakuai.vue";
export default {
  components: {
    huakuai,
  },
  data() {
    let validRules = {
      email: [
        {
          trigger: "blur",
          validator: (rule, val, call) => {
            const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
            if (!val) {
              call(new Error("请输入邮箱"));
            } else if (!reg.test(val)) {
              call(new Error("请输入正确的邮箱"));
            } else {
              this.$axios
                .postJSON("/base/user/email/isexist", { email: val })
                .then((res) => {
                  if (res.code == 200) {
                    if (!res.data) {
                      call();
                      this.emailDisabled = false;
                      this.submitDisabed = false;
                    } else {
                      call(
                        new Error(
                          "该邮箱已被其他账号绑定，请解绑该邮箱或者更换其他邮箱"
                        )
                      );
                    }
                  }
                })
                .catch((err) => {
                  call(
                    new Error(
                      "该邮箱已被其他账号绑定，请解绑该邮箱或者更换其他邮箱"
                    )
                  );
                });
            }
          },
        },
      ],
      mobile_code: [
        {
          trigger: "blur",
          validator: (rule, val, call) => {
            if (!val) {
              call(new Error("请输入手机验证码"));
            } else {
              call();
            }
          },
        },
      ],
      email_code: [
        {
          trigger: "blur",
          validator: (rule, val, call) => {
            if (!val) {
              call(new Error("请输入邮箱验证码"));
            } else {
              call();
            }
          },
        },
      ],
    };
    return {
      emailVisible: false,
      validRules,
      number: 1, //当前步数
      userInfo: {
        mobile: "",
        email: "",
      },
      disable: true, //是否禁用下一步按钮
      codeDisabled: true, //验证码提交
      emailDisabled: true,
      submitDisabed: true, //修改提交

      saveData: {},
      fromData: {
        email: "",
        mobile_code: "",
        email_code: "",
      },

      showWorld: "点击获取验证码",
      timers: null,
      n: 90,
      emailTimers: null,
      emailValid: {
        showWorld: "点击获取验证码",
        n: 90,
      },
      callFn: null,
      down: 3,
    };
  },
  created() {},
  mounted() {},
  methods: {
    countDown() {
      setTimeout(() => {
        if (this.down < 1) {
          this.emailVisible = false;
        } else {
          this.down = this.down - 1;
          this.countDown();
        }
      }, 1000);
    },
    open(data, call) {
      this.emailVisible = true;
      this.userInfo.mobile = data.mobile;
      this.userInfo.email = data.email;
      if (call) {
        this.callFn = call;
      }
    },
    getGenerate() {
      //获取验证码
      let val = this.saveData;
      if (!val.data1) {
        return console.log("图片验证失败");
      }
      let parma = {
        first: {
          x: val.data1.x,
          y: val.data1.y,
          time: val.data1.time,
        },
        second: {
          x: val.data2.x,
          y: val.data2.y,
          time: val.data2.time,
        },
      };
      // console.log('params',parma);
      this.$axios.postJSON("/base/user/email/send_mobile_code", parma).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("获取成功");
            const that = this;
            this.timers = setInterval(() => {
              that.n--;
              if (that.n < 1) {
                clearInterval(that.timers);
                that.$refs.huakuai.resets();
                that.showWorld = "点击获取验证码";
                that.n = 90;
              } else {
                that.showWorld = that.n + "秒后再次获取";
                that.disable = true;
              }
            }, 1000);
          }
        },
        (err) => {
          this.$refs.huakuai.resets();
        }
      );
    },
    emailGenerate() {
      //邮箱验证
      this.$axios
        .postJSON("/base/user/email/send_email_code", {
          email: this.fromData.email,
        })
        .then(
          (res) => {
            if (res.code == 200) {
              this.$message.success("获取成功");
              const that = this;
              this.emailTimers = setInterval(() => {
                that.emailValid.n--;
                if (that.emailValid.n < 1) {
                  clearInterval(that.emailTimers);
                  that.emailValid.showWorld = "点击获取验证码";
                  that.emailValid.n = 90;
                } else {
                  that.emailValid.showWorld =
                    that.emailValid.n + "秒后再次获取";
                  that.emailDisabled = true;
                }
              }, 1000);
            }
          },
          (err) => {
            this.$refs.huakuai.resets();
          }
        );
    },
    isOk(val) {
      if (val.isOk) {
        this.codeDisabled = false;
        this.saveData = val;
      }
    },
    pwdBlur() {
      this.submitDisabed = !this.fromData.new_pwd;
    },
    stepFirstBtn() {
      this.$refs.validForm.validate((valid) => {
        if (valid) {
          this.number = 2;
        }
      });
    },
    submitEmailBtn() {
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
          this.$axios
            .putJSON("/base/user/email", this.fromData)
            .then((res) => {
              this.number = 3;
              this.userInfo.email = this.fromData.email;
              this.down = 3;
              this.countDown();
              this.callFn && this.callFn({ email: this.fromData.email });
            })
            .catch((err) => {});
        }
      });
    },
    loginBtn() {
      if (process.env.NODE_ENV === "production") {
        sessionStorage.removeItem("AccessToken");
       
      } else {
        localStorage.removeItem("AccessToken");
      }
      this.$router.push({
        path: "/login",
      });
    },
    emailValidBtn() {
      if (!this.userInfo.email) {
        return this.$message.error("请先绑定邮箱");
      }
      this.$axios
        .postJSON("/base/user/pwd/send_code", { valid_type: "email" })
        .then((res) => {
          this.$message.success("验证码发送成功");
          this.timers = setInterval(() => {
            that.n--;
            if (that.n < 1) {
              clearInterval(that.timers);
              that.$refs.huakuai.resets();
              that.showWorld = "点击获取验证码";
              that.n = 90;
            } else {
              that.showWorld = that.n + "秒后再次获取";
              that.disable = true;
            }
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          err.msg && this.$message.error(err.msg);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  text-align: center;
  .btn {
    width: 80px;
  }
}
//绑定邮箱弹窗框
.dialog-bind-box {
  .password {
    > .steps {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 450px;
      margin: 0 auto 30px;
      > .list {
        display: flex;
        align-items: center;
        color: rgba(204, 204, 204, 100);
        font-size: 18px;
        letter-spacing: 1px;

        > .number {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          line-height: 20px;
          text-align: center;
          border: 1px solid rgba(204, 204, 204, 100);
          margin-right: 3px;
          font-size: 14px;
        }
      }

      > .active {
        color: rgba(67, 135, 248, 100);

        > .number {
          border-color: rgba(67, 135, 248, 100);
        }
      }

      > span {
        width: 60px;
        height: 1px;
        background-color: rgba(204, 204, 204, 100);
      }
    }
    .huakuai-valid {
      margin-top: 15px;
    }

    .code-box {
      display: flex;
      .code-msg-box {
        width: 150px;
        margin-left: 20px;
      }
    }

    // 验证身份
    > .identity {
      padding: 0 10px;
      > .way {
        display: flex;
        align-items: center;
        height: 32px;
        margin-bottom: 20px;
        .way-label {
          width: 80px;
          box-sizing: border-box;
        }
        > span {
          margin-right: 40px;
          display: flex;
          align-items: center;
          cursor: pointer;
          > i {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(220, 224, 231, 100);
            margin-right: 5px;
            > b {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #f74848;
              display: none;
            }
          }
          > .active {
            border-color: #f74848;
            > b {
              display: block;
            }
          }
        }
      }
    }
    // 改密成功
    > .successful {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 70px;
      > p {
        color: #333333;
        font-size: 18px;
        display: flex;
        align-items: center;
        height: 30px;
        > img {
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }
      }
      > span {
        line-height: 20px;
        margin-top: 10px;
      }
    }
  }
}
</style>
